/*Footer Styles*/

.app-footer {
  padding: 0 32px;
  @include display-flex();
  @include align-items(center);
  @include justify-content(space-between);
  @include box-shadow(0 0px 5px 0 rgba($black, 0.26));
  background-color: $white;
  color: $light-gray;
  min-height: $footer-height;
  height: $footer-height;
  max-height: $footer-height;

  // Add these properties for SweetAlert2 compatibility
  // position: relative; // Change to relative positioning
  // width: 100%; // Use full width of parent container
  // z-index: 10;

  // Keep fixed positioning for SweetAlert2 compatibility
  position: fixed;
  bottom: 0;
  left: auto; // Remove the left positioning
  right: 0;
  width: calc(100% - #{$side-nav-width}); // Initial width
  z-index: 10;
  transition: width 0.3s, transform 0.3s; // Smooth transitions


  // Apply these styles to the footer when sidebar is hidden/collapsed
  .nav-collapsed & {
    width: 100%;
    transform: translateX(0);
  }

  // Handle mobile/responsive layouts
  @media screen and (max-width: 1200px) {
    width: 100%;
    transform: translateX(0);
  }

  // Make footer width responsive to match main content area
  // width: calc(100% - #{$side-nav-width});
  // left: $side-nav-width;

  .jr-link {
    color: $sidebar-text-color;
    &:hover {
      color: $sidebar-hover-color;
    }
  }

  @media screen and (max-width: 400px) {
    font-size: 12px;
    padding: 0 20px;   
  }
}